@charset "UTF-8";
/*
	Theme Name: HD2-Esthetik
	Description: HD2 Esthetik 2017. Un concept HD2 par Hatem+D.
	Version: 1.0
	Author: <a href="http://hatem.ca" target="_blank">HATEM+D</a> (<a href="https://twitter.com/hatemd" target="_blank">@hatemd</a>)
	Author URI: http://hatem.ca
*/
/* ==========================================================================
   Variables
   ========================================================================== */
/* ==========================================================================
   Mixins
   ========================================================================== */
/*
 * Mixin for easy media queries
 * @include bp(tablet) { SCSS here };
*/
/*
 * Mixin for clearfix
 * @include clearfix;
*/
/*
 * Mixin for basic CSS triangles
 * @include triangle(up, #000, 50px)
*/
/*
 * @font-face mixin
 * Bulletproof font-face via Font Squirrel
 * @include fontface('family', 'assets/fonts/', 'myfontname');
 */
@import url("https://use.typekit.net/lgr6lun.css");
.conteneur.navprincipale {
  background: #ffffff;
  max-width: 1280px !important;
  margin: 0 auto; }
  .conteneur.navprincipale .container {
    width: 100%; }

.nav.principale,
.nav.footer {
  display: block;
  width: 100%;
  float: right;
  clear: both; }
  .nav.principale ul,
  .nav.footer ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    float: right; }
    .nav.principale ul li,
    .nav.footer ul li {
      list-style: none;
      float: left; }
      .nav.principale ul li a,
      .nav.footer ul li a {
        font-family: 'imperial-urw', sans-serif;
        color: #3c3c3c;
        display: inline-block;
        font-size: 2rem;
        padding: 50px 40px;
        text-transform: uppercase;
        transition: all 0.4s; }
        .nav.principale ul li a:hover,
        .nav.footer ul li a:hover {
          color: #3c3c3c; }
  .nav.principale .logo,
  .nav.footer .logo {
    padding-top: 15px;
    padding-left: 20px;
    float: left; }

.nav.footer {
  padding-left: 10px;
  padding-right: 10px; }
  .nav.footer ul {
    width: 100%; }
    .nav.footer ul li {
      width: 16.6667%; }
      .nav.footer ul li a {
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        margin: 60px 0 70px 0;
        padding: 60px 0;
        width: 100%;
        text-align: center; }

/* =Nav
-------------------------------------------------------------- */
#nav-trigger {
  position: absolute;
  top: 10px;
  left: 20px;
  display: none;
  text-align: center;
  z-index: 9998; }
  #nav-trigger span {
    display: inline-block;
    padding: 17px 10px 10px 10px;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    z-index: 9999;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; }
    #nav-trigger span.open:after {
      border-left: solid 10px transparent;
      border-top: none;
      border-bottom: solid 10px #fff;
      border-right: solid 10px transparent; }

nav#nav-main {
  background-color: #fbfbfb;
  padding: 10px 0; }
  nav#nav-main ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center; }
  nav#nav-main li {
    display: inline-block;
    border-right: solid 1px #e2e2e2;
    padding: 0 5px; }
    nav#nav-main li:last-child {
      border-right: none; }
  nav#nav-main a {
    font-family: 'imperial-urw', sans-serif;
    display: block;
    color: #3c3c3c !important;
    padding: 10px 30px; }

nav#nav-mobile {
  position: relative;
  display: none; }
  nav#nav-mobile ul {
    display: none;
    list-style-type: none;
    position: absolute;
    top: 77px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: #fbfbfb;
    z-index: 999;
    -webkit-box-shadow: inset 0 12px 12px -10px rgba(60, 60, 60, 0.1);
    -moz-box-shadow: inset 0 12px 12px -10px rgba(60, 60, 60, 0.1);
    box-shadow: inset 0 12px 12px -10px rgba(60, 60, 60, 0.1); }
  nav#nav-mobile li {
    display: block;
    width: 100%;
    color: #3c3c3c; }
    nav#nav-mobile li:last-child {
      border-bottom: none; }
  nav#nav-mobile a {
    display: block;
    color: #3c3c3c;
    font-family: 'imperial-urw', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    padding: 30px 30px;
    -webkit-box-shadow: inset 0 12px 12px -10px rgba(60, 60, 60, 0.1);
    -moz-box-shadow: inset 0 12px 12px -10px rgba(60, 60, 60, 0.1);
    box-shadow: inset 0 12px 12px -10px rgba(60, 60, 60, 0.1); }
    nav#nav-mobile a:hover,
    nav#nav-mobile a:active nav#nav-mobile a:focus {
      text-decoration: none; }
    nav#nav-mobile a:hover {
      background: #000000;
      color: #fff; }

/* =Media Queries
-------------------------------------------------------------- */
@media all and (max-width: 991px) {
  #nav-trigger {
    display: block; }
  nav#nav-main {
    display: none; }
  nav#nav-mobile {
    display: block; } }

.icone-menu {
  display: inline-block;
  cursor: pointer; }

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s; }

/* Rotate first bar */
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-8px, 7px);
  transform: rotate(-45deg) translate(-8px, 7px); }

/* Fade out the second bar */
.change .bar2 {
  opacity: 0; }

/* Rotate last bar */
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px); }

#carrousel-accueil h2 {
  color: #ffffff;
  text-shadow: 1px 1px 2px #646464;
  padding-right: 100px;
  padding-left: 100px; }
  @media only screen and (max-width: 767px) {
    #carrousel-accueil h2 {
      font-size: 3rem;
      line-height: 3rem; } }

#carrousel-accueil .btn_carrousel {
  border-color: #fff;
  color: #fff;
  transition: 0.4s; }
  #carrousel-accueil .btn_carrousel:hover {
    background-color: #fff;
    color: #000 !important; }

.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.4);
  background-image: url(../img/ico/swiper-fleche-gauche.svg) !important;
  background-size: 60px 90px !important;
  font-size: 2rem !important;
  padding: 107px 50px;
  top: 39% !important;
  right: 0 !important;
  z-index: 20 !important;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: transparent; }

.swiper-button-next {
  background-position: 27px center !important;
  background-image: url(../img/ico/swiper-fleche-droit.svg) !important; }

.swiper-button-prev {
  background-position: 17px center !important;
  left: 0 !important; }

/*
.retour-haut {
  background: url(../img/ico/fleche_haut.png) no-repeat center center;
  cursor: pointer;
  display: none;
  height: 55px;
  padding-right: 1em;
  position: fixed;
  right: 3rem;
  bottom: 7rem;
  width: 55px;
  z-index: 999;
}
*/
.retour-haut {
  background: #ffffff;
  border-radius: 70px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(60, 60, 60, 0.2);
  -moz-box-shadow: 0 1px 4px 0 rgba(60, 60, 60, 0.2);
  box-shadow: 0 1px 4px 0 rgba(60, 60, 60, 0.2);
  height: 70px;
  margin: 0 auto;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
  width: 70px;
  text-align: center;
  z-index: 100 !important;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease; }
  .retour-haut::before {
    font-size: 28px !important;
    padding-top: 20px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease; }
  .retour-haut:hover, .retour-haut:active, .retour-haut:focus {
    box-shadow: 0 1px 4px 0 #3c3c3c;
    cursor: pointer; }
    .retour-haut:hover::before:hover, .retour-haut:active::before:hover, .retour-haut:focus::before:hover {
      color: #ffffff; }

/* ==========================================================================
   Border-box all the things!
   ========================================================================== */
*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*
// Déboguage de contenant qui sort du viewport
// http://wernull.com/2013/04/debug-ghost-css-elements-causing-unwanted-scrolling/ 
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}
*/
/* ==========================================================================
   General
   ========================================================================== */
* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Prompt", sans-serif;
  outline: 0 !important;
  text-decoration: none !important; }

html {
  font-size: 62.5% !important; }

body {
  background: #fafafa;
  font-size: 1.4rem;
  opacity: 0;
  overflow-x: hidden; }
  body.accueil {
    overflow-x: hidden !important; }

input::-moz-focus-inner,
object,
embed,
a:hover,
a:active,
a:focus {
  outline: 0 !important; }

a {
  color: #3c3c3c;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }
  a:hover {
    color: #3c3c3c; }

p a {
  font-weight: bold;
  text-decoration: underline !important; }

h1 {
  font-family: 'imperial-urw', sans-serif;
  font-size: 4.8rem;
  padding-bottom: 30px; }

h2 {
  font-family: 'imperial-urw', sans-serif;
  font-size: 4rem;
  padding-bottom: 30px; }

h3 {
  font-family: 'imperial-urw', sans-serif;
  font-size: 3.8rem; }

p {
  font-size: 20px;
  line-height: 1.35; }

.btn,
[type="button"],
[type="reset"],
[type="submit"],
[type="image"],
[type="file"] {
  border: 2px solid #3c3c3c;
  border-radius: 0;
  color: #3c3c3c;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3.8rem;
  padding: 15px 50px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }
  .btn:hover,
  [type="button"]:hover,
  [type="reset"]:hover,
  [type="submit"]:hover,
  [type="image"]:hover,
  [type="file"]:hover {
    background: #000000;
    border: 2px solid #000000;
    color: #ffffff !important; }

[type="button"],
[type="reset"],
[type="submit"],
[type="image"],
[type="file"] {
  margin-top: 3rem; }

.medias-sociaux {
  position: absolute;
  right: 0; }
  .medias-sociaux a {
    padding: 2px;
    margin: 0 3px;
    float: left; }
    .medias-sociaux a::before {
      color: #3c3c3c;
      width: 30px; }
    .medias-sociaux a:hover::before {
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      color: #d9c6b4 !important; }

.current_page_item a,
.current_menu_item a {
  color: #000000 !important;
  text-decoration: underline !important; }

#nav-mobile a:hover {
  color: #ffffff !important; }

#presentation p {
  padding-bottom: 20px; }

.swiper-wrapper.disabled {
  transform: translate3d(0px, 0, 0) !important; }

.swiper-pagination.disabled {
  display: none; }

.disabled .swiper-button-prev,
.disabled .swiper-button-next {
  display: none !important; }

@media only screen and (max-width: 1700px) {
  .bandeau-bg-xlarge {
    display: none; } }

@media only screen and (min-width: 1701px) {
  .bandeau-bg-xlarge {
    display: block; } }

@media only screen and (max-width: 767px) {
  .bandeau-bg-desktop {
    display: none; } }

@media only screen and (min-width: 768px) {
  .bandeau-bg-desktop {
    display: block; } }

@media only screen and (min-width: 1700px) {
  .bandeau-bg-desktop {
    display: none; } }

@media only screen and (min-width: 600px) {
  .bandeau-bg-ipad {
    display: block; } }

@media only screen and (max-width: 599px) {
  .bandeau-bg-ipad {
    display: none; } }

@media only screen and (min-width: 768px) {
  .bandeau-bg-ipad {
    display: none; } }

@media only screen and (max-width: 599px) {
  .bandeau-bg-mobile {
    display: block; } }

@media only screen and (min-width: 600px) {
  .bandeau-bg-mobile {
    display: none; } }

/*

[type="email"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="url"],
textarea,

[type="button"],
[type="reset"],
[type="submit"],
[type="image"],
[type="file"],

[type="checkbox"],
[type="radio"],
[type="color"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="number"],
[type="range"],
[type="time"],
[type="week"],

[type="hidden"],

*/
[type="email"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="url"],
textarea {
  background: #fbfbfb;
  border: 1px solid #3c3c3c;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 20px 25px;
  width: 100%;
  font-family: "Prompt" !important;
  resize: none;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }
  [type="email"]:active, [type="email"]:focus,
  [type="password"]:active,
  [type="password"]:focus,
  [type="search"]:active,
  [type="search"]:focus,
  [type="tel"]:active,
  [type="tel"]:focus,
  [type="text"]:active,
  [type="text"]:focus,
  [type="url"]:active,
  [type="url"]:focus,
  textarea:active,
  textarea:focus {
    background: #ffffff;
    border: 1px solid #000000; }

textarea {
  margin-bottom: 15px; }

[type="button"],
[type="reset"],
[type="submit"],
[type="image"],
[type="file"] {
  background: #fbfbfb;
  float: right;
  font-family: "Prompt" !important; }
  [type="button"]:hover,
  [type="reset"]:hover,
  [type="submit"]:hover,
  [type="image"]:hover,
  [type="file"]:hover {
    cursor: pointer; }

span.wpcf7-not-valid-tip {
  display: none !important; }

::-webkit-input-placeholder {
  color: #aeaeae; }

::-moz-placeholder {
  color: #aeaeae; }

:-ms-input-placeholder {
  color: #aeaeae; }

:-moz-placeholder {
  color: #aeaeae; }

/* Styliser les champs erronés */
input[aria-invalid="true"]:focus, input[aria-invalid="true"]:active,
textarea[aria-invalid="true"]:focus,
textarea[aria-invalid="true"]:active,
select[aria-invalid="true"]:focus,
select[aria-invalid="true"]:active {
  color: #3c3c3c !important; }

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  color: rgba(0, 0, 0, 0.4); }

input:focus:-moz-placeholder, textarea:focus:-moz-placeholder {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  color: rgba(0, 0, 0, 0.4); }

input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  color: rgba(0, 0, 0, 0.4); }

input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  color: rgba(0, 0, 0, 0.4); }

/* Styliser les champs erronés */
div.wpcf7-mail-sent-ok {
  border: none !important;
  background: #000000;
  color: #ffffff; }

div.wpcf7-validation-errors,
span.wpcf7-not-valid-tip {
  /*
  background: $bleu;
	border: none;
	clear: both;
  color: $blanc;
	font-family: 'HelveticaNeue', 'Sans-serif';
	letter-spacing: 0.05rem;
  margin: 0;
	padding: 10px;
  position: relative;
  right: 20px;
	bottom: -20px;
  left: 0;
	text-align: center;
	z-index: 100;
	*/
  display: none !important; }

input[aria-invalid="true"],
select[aria-invalid="true"],
textarea[aria-invalid="true"] {
  border-color: #f93348 !important;
  border: 1px solid #f93348 !important;
  color: #f93348 !important; }

input[aria-invalid="true"]::-webkit-input-placeholder,
textarea[aria-invalid="true"]::-webkit-input-placeholder,
select[aria-invalid="true"]::-webkit-input-placeholder {
  color: #f93348;
  /* Chrome/Opera/Safari */ }

input[aria-invalid="true"]::-moz-placeholder,
textarea[aria-invalid="true"]::-moz-placeholder,
select[aria-invalid="true"]::-moz-placeholder {
  color: #f93348; }

input[aria-invalid="true"]:-ms-input-placeholder,
textarea[aria-invalid="true"]:-ms-input-placeholder,
select[aria-invalid="true"]:-ms-input-placeholder {
  color: #f93348; }

input[aria-invalid="true"]:-moz-placeholder,
textarea[aria-invalid="true"]:-moz-placeholder,
select[aria-invalid="true"]:-moz-placeholder {
  color: #f93348; }

::selection {
  background: #000000;
  color: #FFF; }

::-webkit-selection {
  background: #000000;
  color: #FFF; }

::-moz-selection {
  background: #000000;
  color: #FFF; }

.menu-bg {
  background-color: #ffffff; }

.header.header {
  background-color: #000000;
  position: relative;
  z-index: 1000;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2); }
  .header.header aside.header-infos {
    background-color: #000000;
    color: #ffffff;
    font-size: 2rem;
    height: 60px;
    padding-top: 15px;
    padding-right: 30px;
    padding-bottom: 15px;
    max-width: 1280px;
    margin: 0 auto; }
    .header.header aside.header-infos .container {
      width: 100%; }
    .header.header aside.header-infos .header-telephone {
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.35;
      letter-spacing: normal; }
      .header.header aside.header-infos .header-telephone .icon-phone {
        color: #ffffff;
        position: relative;
        top: 3px;
        left: 3px; }
      .header.header aside.header-infos .header-telephone a {
        color: #ffffff; }
        .header.header aside.header-infos .header-telephone a:hover {
          color: #ffffff; }
    .header.header aside.header-infos .notification-ouverture {
      margin-left: 55px; }
      .header.header aside.header-infos .notification-ouverture::before {
        width: 45px;
        position: relative;
        top: 2px;
        left: 2px; }
      .header.header aside.header-infos .notification-ouverture .mb-bhi-oc-text {
        color: #ffffff; }
      .header.header aside.header-infos .notification-ouverture .jour-semaine {
        float: right;
        margin-left: 30px;
        position: relative; }
        .header.header aside.header-infos .notification-ouverture .jour-semaine::before {
          content: '';
          position: absolute;
          left: 0;
          width: 2px;
          height: 25px;
          top: 4px;
          background: #c9c8c8;
          margin-left: -15px; }
        .header.header aside.header-infos .notification-ouverture .jour-semaine td:first-of-type {
          padding-right: 12px;
          position: relative; }
          .header.header aside.header-infos .notification-ouverture .jour-semaine td:first-of-type::after {
            content: ':';
            position: absolute;
            right: 0;
            padding-right: 3px; }
        .header.header aside.header-infos .notification-ouverture .jour-semaine .mabel-bhi-businesshours tr {
          display: none; }
          .header.header aside.header-infos .notification-ouverture .jour-semaine .mabel-bhi-businesshours tr.mbhi-is-current {
            text-transform: capitalize;
            display: block; }

.footer-wrapper {
  background: #ffffff; }

footer.footer {
  display: block;
  line-height: 1.35;
  max-width: 1280px;
  margin: 0 auto;
  position: relative; }
  footer.footer .logo {
    padding: 110px 100px 40px 15px;
    width: 100%; }
    footer.footer .logo a {
      display: block;
      margin-left: 10px;
      padding: 0 0 20px 0; }
  footer.footer .footer-global {
    color: #3c3c3c;
    font-size: 2rem;
    line-height: 1.35;
    width: 100%; }
    footer.footer .footer-global [class^="icon-"]:before,
    footer.footer .footer-global [class*=" icon-"]:before {
      font-size: 28px;
      padding-right: 0px;
      width: 30px; }
    footer.footer .footer-global .mabel-bhi-businesshours {
      text-transform: capitalize; }
      footer.footer .footer-global .mabel-bhi-businesshours td::after {
        content: ':';
        padding-left: 2px; }
      footer.footer .footer-global .mabel-bhi-businesshours td + td::after {
        content: none; }
      footer.footer .footer-global .mabel-bhi-businesshours .mbhi-is-current {
        font-weight: bold; }
    footer.footer .footer-global .footer-coordonnees {
      float: left; }
      footer.footer .footer-global .footer-coordonnees a {
        color: #3c3c3c;
        text-decoration: none !important;
        position: relative; }
        footer.footer .footer-global .footer-coordonnees a:hover:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0; }
      footer.footer .footer-global .footer-coordonnees p {
        padding-bottom: 20px; }
      footer.footer .footer-global .footer-coordonnees address {
        margin-left: 68px;
        margin-bottom: 30px; }
        footer.footer .footer-global .footer-coordonnees address span {
          margin-left: -68px; }
          footer.footer .footer-global .footer-coordonnees address span a {
            color: #3c3c3c !important; }
            footer.footer .footer-global .footer-coordonnees address span a:hover {
              color: #000000; }
    footer.footer .footer-global .footer-courriel {
      float: left; }
      footer.footer .footer-global .footer-courriel a {
        color: #3c3c3c; }
        footer.footer .footer-global .footer-courriel a:hover {
          color: #000000; }
    footer.footer .footer-global .footer-horaire {
      margin-left: 65px;
      padding: 30px 0 0 0; }
      footer.footer .footer-global .footer-horaire td {
        padding-right: 20px; }
      footer.footer .footer-global .footer-horaire span {
        float: left;
        margin-left: -65px; }
  footer.footer [class^="icon-"]:before, footer.footer [class*=" icon-"]:before {
    color: #000000 !important; }

.copyright {
  background: #fafafa;
  display: block;
  overflow: hidden;
  margin-top: 75px;
  padding: 20px;
  width: 100%; }
  .copyright .container {
    max-width: 1280px; }
  .copyright .hatem {
    display: inline-block;
    font-size: 2rem;
    padding: 0 15px; }
    .copyright .hatem strong {
      font-weight: normal; }
    .copyright .hatem a {
      color: #3c3c3c; }
      .copyright .hatem a:hover {
        color: #000000; }
  .copyright .medias-sociaux {
    padding-right: 20px; }
    .copyright .medias-sociaux a::before {
      color: #3c3c3c; }
    .copyright .medias-sociaux a:hover::before {
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      color: #000000; }
    .copyright .medias-sociaux [class^="icon-"]:before:hover,
    .copyright .medias-sociaux [class*=" icon-"]:before:hover {
      color: #000000 !important; }

.conteneur-liens-cta {
  border-radius: 8px;
  -webkit-box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.3) !important; }

.liens-cta {
  display: block;
  margin-top: -150px;
  position: relative;
  width: 100%;
  z-index: 100; }
  .liens-cta nav {
    max-width: 930px !important;
    margin: 0 auto; }
    .liens-cta nav ul {
      border-radius: 8px;
      margin: 0 -1px 0 0;
      overflow: hidden;
      padding: 0; }
    .liens-cta nav li {
      list-style: none; }
      .liens-cta nav li:first-of-type a, .liens-cta nav li:last-of-type a {
        padding-top: 170px; }
      .liens-cta nav li:first-of-type a {
        background: #dfcfbf; }
      .liens-cta nav li:last-of-type a {
        background: #d3bda9; }
      .liens-cta nav li a {
        background: #d9c6b4;
        display: flex;
        display: -webkit-flex;
        float: left;
        min-height: 305px;
        padding-top: 172px;
        position: relative;
        text-align: center;
        width: calc(100%/3);
        -webkit-align-items: center;
        align-items: center; }
        .liens-cta nav li a:hover {
          background: #d7c3b1; }
        .liens-cta nav li a figure,
        .liens-cta nav li a h2 {
          clear: both;
          display: inline-block;
          width: 100%; }
        .liens-cta nav li a figure {
          position: absolute;
          top: 24%; }
          .liens-cta nav li a figure img {
            max-width: 70px; }
        .liens-cta nav li a h2 {
          color: #ffffff;
          font-size: 3rem;
          line-height: 3.5rem;
          margin: 0 auto;
          max-width: 100%;
          width: 80% !important; }

.texte-presentation {
  overflow: hidden;
  width: 100%; }
  .texte-presentation article {
    max-width: 930px;
    margin: 0 auto;
    padding: 120px 30px; }

.types-service {
  background: #ffffff;
  padding: 120px 0;
  width: 100%; }
  .types-service article {
    max-width: 930px;
    margin: 0 auto;
    text-align: center; }
    .types-service article figure {
      float: left; }
      .types-service article figure img {
        display: block;
        width: 100%; }
    .types-service article div {
      float: left;
      margin-bottom: 65px;
      padding-left: 0 !important;
      text-align: left; }
      .types-service article div:last-of-type {
        margin-bottom: 0 !important; }
  .types-service h1 {
    padding-bottom: 90px;
    text-align: left; }
  .types-service h2 {
    font-weight: 300; }
  .types-service a {
    clear: both;
    display: inline-block; }

.details-services {
  background: #000000;
  min-height: 950px;
  position: relative; }
  .details-services .boite {
    background: white;
    border-radius: 8px;
    float: right;
    max-width: 660px;
    padding: 85px 155px 70px 150px;
    position: absolute;
    top: 100px;
    right: 0;
    -webkit-box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.25); }
    .details-services .boite h1 {
      font-size: 4.4rem; }
    .details-services .boite ul {
      padding-left: 0; }
    .details-services .boite li {
      margin-left: -60px;
      padding-left: 60px;
      position: relative;
      font-size: 2rem;
      list-style: none;
      padding-bottom: 3rem; }
      .details-services .boite li::before {
        content: '';
        background: url(../img/ico/crochet.svg) no-repeat center center;
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 24px; }
  .details-services a.btn {
    border-color: #000000;
    color: #000000;
    font-weight: 600;
    letter-spacing: 0.03rem;
    margin-top: 20px;
    margin-left: 30px;
    padding: 20px 50px; }

#contact #carte-contact {
  width: 100%;
  display: block;
  overflow: hidden; }
  #contact #carte-contact .gm-style-cc,
  #contact #carte-contact .gmnoprint.gm-style-mtc {
    display: none; }
  #contact #carte-contact .gm-style-pbc {
    display: none !important; }

#contact #presentation {
  background: #000000;
  color: #ffffff;
  clear: both;
  max-width: 100%; }
  #contact #presentation .container {
    margin: 0 auto;
    max-width: 930px; }

#contact #conteneur-coordonnees-horaire {
  margin: 0 auto;
  max-width: 930px;
  padding: 60px 0 0; }
  #contact #conteneur-coordonnees-horaire .coordonnees,
  #contact #conteneur-coordonnees-horaire .horaire {
    width: 100%; }
  #contact #conteneur-coordonnees-horaire a {
    color: #3c3c3c;
    font-weight: 400 !important;
    text-decoration: none !important;
    font-size: 2rem; }
  #contact #conteneur-coordonnees-horaire [class^="icon-"]:before,
  #contact #conteneur-coordonnees-horaire [class*=" icon-"]:before {
    text-align: left;
    font-size: 3.2rem; }
  #contact #conteneur-coordonnees-horaire .horaire {
    padding-top: 110px;
    padding-left: 50px;
    margin-bottom: 75px;
    position: relative;
    margin-top: -50px;
    z-index: 100; }
    @media only screen and (max-width: 767px) {
      #contact #conteneur-coordonnees-horaire .horaire {
        padding-right: 30px; } }
    #contact #conteneur-coordonnees-horaire .horaire .titreheures {
      margin-left: -50px; }
    #contact #conteneur-coordonnees-horaire .horaire::before {
      position: absolute;
      left: 15px;
      top: 190px; }
    #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours {
      position: relative !important; }
      @media only screen and (max-width: 767px) {
        #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours {
          width: 100%; } }
      #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours::after {
        content: '';
        border: 1px solid #3c3c3c;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -50px; }
      #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours tr:last-of-type td {
        padding-bottom: 25px; }
      #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours tr td {
        text-transform: capitalize;
        padding: 18px 25px 0;
        font-size: 2rem; }
        @media only screen and (max-width: 400px) {
          #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours tr td + td {
            padding-right: 0;
            padding-left: 0; } }
  #contact #conteneur-coordonnees-horaire .notice-ouverture {
    background: #fafafa !important;
    display: block;
    font-size: 15px;
    font-weight: bold;
    margin: 0 50px 0 0;
    padding: 20px 0 30px 0;
    position: relative;
    right: 0;
    left: 0;
    z-index: 150; }
    @media only screen and (min-width: 992px) {
      #contact #conteneur-coordonnees-horaire .notice-ouverture {
        right: 0; } }
    @media only screen and (max-width: 991px) {
      #contact #conteneur-coordonnees-horaire .notice-ouverture {
        margin-right: 15px;
        padding: 20px 30px 30px 74px; } }
    @media only screen and (max-width: 767px) {
      #contact #conteneur-coordonnees-horaire .notice-ouverture {
        margin-left: 30px;
        margin-right: 30px;
        padding: 20px 25px 30px 0px; } }
  #contact #conteneur-coordonnees-horaire .coordonnees {
    margin: 0 auto; }
    #contact #conteneur-coordonnees-horaire .coordonnees .infos p {
      padding: 50px 0 25px;
      width: 100%; }
      #contact #conteneur-coordonnees-horaire .coordonnees .infos p span::before {
        position: relative;
        top: 6px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        #contact #conteneur-coordonnees-horaire .coordonnees .infos p + p a {
          font-size: 1.6rem; } }
    #contact #conteneur-coordonnees-horaire .coordonnees .adresse {
      padding: 50px 0 25px;
      margin-left: 65px; }
      #contact #conteneur-coordonnees-horaire .coordonnees .adresse span:first-child {
        font-weight: bolder; }
      #contact #conteneur-coordonnees-horaire .coordonnees .adresse span::before {
        margin-left: -60px;
        position: relative;
        top: 12px; }
      #contact #conteneur-coordonnees-horaire .coordonnees .adresse span a {
        margin-left: 0; }
  #contact #conteneur-coordonnees-horaire .formulaire-contact {
    padding: 90px 30px 185px 20px; }
    #contact #conteneur-coordonnees-horaire .formulaire-contact h2 {
      font-weight: 300; }
    #contact #conteneur-coordonnees-horaire .formulaire-contact > p {
      padding-bottom: 30px; }
    #contact #conteneur-coordonnees-horaire .formulaire-contact p {
      overflow: hidden !important; }
      #contact #conteneur-coordonnees-horaire .formulaire-contact p input[type="submit"] {
        margin-top: 0; }

#contact [class^="icon-"]:before,
#contact [class*=" icon-"]:before {
  color: #000000 !important; }

#faq #presentation {
  background: #000000;
  max-width: 100%;
  color: #ffffff;
  background-color: #000000;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2); }
  #faq #presentation p {
    max-width: 930px; }

#faq .conteneur-accordeon-faq {
  padding: 160px 0 200px 0; }

#faq .row {
  margin: 0 auto;
  max-width: 930px; }

#faq .panel-group {
  border-radius: 0;
  width: 100%;
  -webkit-box-shadow: 0px 4px 6px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px 0 rgba(0, 0, 0, 0.2); }
  #faq .panel-group .panel:first-of-type h2 > a {
    border-radius: 0; }
  #faq .panel-group .panel:last-of-type h2 > a {
    border-radius: 0 !important; }
  #faq .panel-group .panel-heading h2 {
    font-size: 2rem;
    margin: -2px 0;
    padding-bottom: 0; }
    #faq .panel-group .panel-heading h2 a {
      background: #ffffff;
      border: 2px solid #000000;
      color: #3c3c3c;
      display: block;
      font-weight: 400;
      padding: 23px 50px 23px 130px;
      position: relative; }
      #faq .panel-group .panel-heading h2 a:hover {
        background: rgba(217, 198, 180, 0.1); }
      #faq .panel-group .panel-heading h2 a .nav-trigger {
        display: inline-block;
        left: -100px;
        position: absolute;
        top: 28px;
        left: 54px; }
        #faq .panel-group .panel-heading h2 a .nav-trigger .bar1, #faq .panel-group .panel-heading h2 a .nav-trigger .bar2 {
          width: 22px;
          height: 4px; }
        #faq .panel-group .panel-heading h2 a .nav-trigger .bar2 {
          -webkit-transform: rotate(-90deg) translate(10px, 0px);
          transform: rotate(-90deg) translate(10px, 0px); }
          #faq .panel-group .panel-heading h2 a .nav-trigger .bar2.change {
            -webkit-transform: rotate(0deg) translate(0, -10px);
            transform: rotate(0deg) translate(0, -10px); }
  #faq .panel-group .panel-heading .panel-title [aria-expanded="true"] {
    background: #000000;
    border: 2px solid #000000;
    color: #ffffff;
    font-weight: 700; }
    #faq .panel-group .panel-heading .panel-title [aria-expanded="true"] .nav-trigger [class^="bar"] {
      background: #ffffff; }
  #faq .panel-group .panel-collapse {
    border: 2px solid #979797;
    padding: 0 40px 0 130px; }
    #faq .panel-group .panel-collapse .question-reponse {
      padding-top: 20px; }
      #faq .panel-group .panel-collapse .question-reponse:first-of-type {
        padding-top: 40px; }
      #faq .panel-group .panel-collapse .question-reponse:last-of-type {
        padding-bottom: 30px; }
      #faq .panel-group .panel-collapse .question-reponse h3 {
        font-weight: 700;
        font-size: 2.5rem;
        padding-bottom: 25px; }
      #faq .panel-group .panel-collapse .question-reponse p {
        color: #000000;
        font-size: 1.8rem; }
      #faq .panel-group .panel-collapse .question-reponse h3 {
        font-weight: 400;
        position: relative; }
        #faq .panel-group .panel-collapse .question-reponse h3::before {
          content: 'Q';
          position: absolute;
          top: 5px;
          left: -74px;
          font-size: 1.8rem; }
      #faq .panel-group .panel-collapse .question-reponse p {
        position: relative; }
        #faq .panel-group .panel-collapse .question-reponse p:first-of-type::before {
          content: 'R';
          position: absolute;
          top: 1px;
          left: -74px; }

#confiance-distinction,
#notre-equipe,
#presentation {
  margin: 0 auto;
  max-width: 990px;
  padding: 120px 30px; }

#presentation {
  padding: 120px 30px 90px; }
  #presentation p {
    max-width: 930px; }

#confiance-distinction,
#notre-equipe,
.bandeau-marketing {
  padding: 60px 30px; }

#confiance-distinction {
  padding-top: 120px; }

#clinique #notre-equipe {
  padding-bottom: 45px; }

#clinique #notre-mission {
  background-color: #000;
  overflow: hidden; }
  #clinique #notre-mission article {
    color: #ffffff;
    float: left;
    padding: 70px 170px 30px; }
    #clinique #notre-mission article h1 {
      padding-bottom: 45px; }
  #clinique #notre-mission div {
    float: left; }

#clinique .conteneur_fiche_equipe {
  border-radius: 8px;
  margin-bottom: 5px;
  width: 100%;
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3) !important; }

#clinique .carrousel-conteneur {
  border-radius: 8px;
  padding-bottom: 160px; }

#clinique .swiper-container {
  padding-top: 10px; }

#clinique #carrousel-clinique {
  padding-left: 8px;
  padding-right: 8px; }
  #clinique #carrousel-clinique .swiper-button-next,
  #clinique #carrousel-clinique .swiper-button-prev {
    top: 28% !important;
    background-size: 30px 60px !important;
    padding: 60px 30px; }
  #clinique #carrousel-clinique .swiper-button-prev {
    background-position: 13px center !important;
    background-size: 30px 60px !important;
    padding: 60px 30px; }
  #clinique #carrousel-clinique .swiper-button-next {
    background-position: 20px center !important; }

#clinique .bandeau-marketing {
  overflow: hidden; }
  #clinique .bandeau-marketing img {
    display: block;
    width: 100%; }

#clinique div.swiper-slide {
  width: 100%;
  background: transparent; }
  #clinique div.swiper-slide .img-fiche {
    border-radius: 8px 8px 0 0; }
  #clinique div.swiper-slide .infos-fiche {
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    font-size: 2.4rem;
    line-height: 1.25;
    padding-top: 40px;
    padding-bottom: 25px;
    padding-left: 30px;
    text-align: left;
    min-height: 200px; }
    #clinique div.swiper-slide .infos-fiche .titre-professionnel {
      font-size: 16px; }
    #clinique div.swiper-slide .infos-fiche .nom {
      margin-bottom: 2px; }
    #clinique div.swiper-slide .infos-fiche .telephone {
      padding-top: 5px;
      margin-left: -62px; }
      #clinique div.swiper-slide .infos-fiche .telephone a {
        color: #3c3c3c;
        text-decoration: none !important;
        font-size: 14px; }
        #clinique div.swiper-slide .infos-fiche .telephone a:hover {
          color: #000000; }
      #clinique div.swiper-slide .infos-fiche .telephone::before {
        visibility: hidden; }

.services .tous {
  background: #fbfbfb;
  padding: 0; }

.services article {
  padding: 0 30px 35px 30px;
  max-width: 1280px !important;
  width: 100%; }
  .services article.sections-services {
    padding: 70px 0; }

.services #presentation {
  background-color: #000000;
  color: #ffffff;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px; }
  .services #presentation .row {
    margin: 0 auto;
    max-width: 930px; }
    .services #presentation .row article {
      padding: 0; }

.services .conteneur-sections-services {
  background: #fbfbfb;
  padding: 80px 0 70px 0; }
  .services .conteneur-sections-services:first-of-type {
    padding: 120px 0 60px 0; }
  .services .conteneur-sections-services:last-of-type {
    padding: 80px 0 130px 0; }
  .services .conteneur-sections-services.pair {
    padding: 80px 0 60px 0; }
  .services .conteneur-sections-services .container {
    max-width: 1280px !important;
    width: 100%; }
  .services .conteneur-sections-services.pair {
    background: #ffffff; }

.services .row .row {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.services .conteneur-fiches {
  padding-right: 15px;
  padding-left: 15px;
  width: 100% !important; }
  .services .conteneur-fiches > div {
    float: left; }

.services .fiche-service {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px; }
  .services .fiche-service .contenu-int-fiche {
    position: relative;
    background: #ffffff !important;
    border-radius: 8px;
    -webkit-box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.3) !important;
    -moz-box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.3) !important;
    width: 100%; }
    .services .fiche-service .contenu-int-fiche .flexire p {
      margin-bottom: 125px; }
    .services .fiche-service .contenu-int-fiche .flexire div.btn {
      text-align: center; }
    .services .fiche-service .contenu-int-fiche div {
      border-radius: 8px 8px 0 0; }
    .services .fiche-service .contenu-int-fiche h3 {
      background: #d9c6b4;
      color: #ffffff;
      clear: both;
      display: block;
      font-size: 2.4rem;
      font-weight: 700;
      height: 120px;
      line-height: 1.25;
      margin-bottom: 0;
      position: relative;
      text-align: center; }
      .services .fiche-service .contenu-int-fiche h3 span {
        display: block;
        font-family: "imperial-urw", sans-serif;
        font-size: 2.4rem;
        line-height: 1.25;
        padding: 0 30px;
        width: 100%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%); }
    .services .fiche-service .contenu-int-fiche p {
      color: #3c3c3c;
      font-size: 20px;
      line-height: 1.35;
      margin: 0;
      padding: 65px 40px 10px 40px; }
    .services .fiche-service .contenu-int-fiche a {
      display: block;
      border: none; }
    .services .fiche-service .contenu-int-fiche .btn {
      border: none;
      display: block;
      border-radius: 0 0 8px 8px;
      margin-top: 0;
      padding: 35px 0; }

.service .opt-retour {
  background: #000000;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2); }
  .service .opt-retour a {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    color: #ffffff;
    display: inline-block;
    font-size: 3.2rem;
    font-weight: 300;
    padding: 40px 40px 40px 10px; }
    .service .opt-retour a::before {
      color: #ffffff;
      font-size: 3.5rem;
      text-align: center;
      width: 60px;
      position: relative;
      left: 5px;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease; }
    .service .opt-retour a:hover {
      background: #000000;
      border-color: transparent; }
      .service .opt-retour a:hover::before {
        left: -10px; }

.service .details-services .boite {
  top: 50px; }

.service .swiper-container {
  padding: 0; }

.service #resultats {
  min-height: 530px;
  overflow: hidden; }
  .service #resultats article {
    float: left;
    padding: 70px 30px 30px 40px; }
    .service #resultats article h1 {
      padding-bottom: 25px; }
  .service #resultats #carrousel-service {
    float: left;
    overflow: hidden; }
    .service #resultats #carrousel-service .swiper-button-next,
    .service #resultats #carrousel-service .swiper-button-prev {
      top: 34% !important; }

.service #utilisation {
  background: #000000;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  padding: 120px 30px;
  width: 100%; }
  .service #utilisation h2 {
    display: block;
    text-align: center;
    width: 100%; }
  .service #utilisation li {
    clear: both;
    display: block;
    font-size: 3rem;
    margin-left: 30%;
    text-align: left;
    position: relative;
    width: 100%; }
    .service #utilisation li::before {
      content: "";
      color: #ffffff;
      background: url(../img/ico/crochet_blanc.svg) no-repeat center center;
      position: absolute;
      top: 8px;
      left: -60px;
      width: 32px;
      height: 24px; }
  .service #utilisation a.btn {
    border-color: #ffffff;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 50px; }
    .service #utilisation a.btn:hover {
      background: #ffffff;
      color: #000000 !important; }

.service .btn-technique {
  color: #ffffff; }

.news.services article {
  display: flex;
  flex-wrap: wrap; }

.news.services .fiche-service {
  flex-direction: column; }

.news.services .flexire span {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 16px; }

.news_single .date {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 16px;
  width: 100%; }

.sharing {
  margin-top: 50px; }

@media only screen and (max-width: 767px) {
  .news.services article {
    padding: 0; } }

#technologies {
  background: #fbfbfb;
  overflow: hidden; }
  #technologies #presentation {
    overflow: hidden; }
    #technologies #presentation p {
      width: 100%; }
  #technologies section.conteneur-technologies {
    background: #ffffff;
    display: flex;
    overflow: hidden;
    padding: 0;
    /*
		div.img-2 {
			background-position: left 40% top 10% !important;
		}
		div.img-6 {
			background-position: center top 40% !important;
		}
		*/
    clear: both; }
    #technologies section.conteneur-technologies div.mission-img, #technologies section.conteneur-technologies article {
      float: left; }
      #technologies section.conteneur-technologies div.mission-img h2, #technologies section.conteneur-technologies article h2 {
        font-weight: 300; }
    #technologies section.conteneur-technologies article {
      background: #000000;
      color: #ffffff;
      overflow: hidden;
      padding: 110px 120px; }
      #technologies section.conteneur-technologies article li {
        font-size: 20px; }
    #technologies section.conteneur-technologies .btn {
      background: #000000;
      color: #ffffff;
      border: none; }
      #technologies section.conteneur-technologies .btn:hover {
        background: #ffffff;
        color: #000000 !important; }
    #technologies section.conteneur-technologies.pair {
      display: flex; }
      #technologies section.conteneur-technologies.pair article {
        background: #fbfbfb;
        color: #3c3c3c;
        float: left !important;
        order: 1; }
      #technologies section.conteneur-technologies.pair div.mission-img {
        float: right !important;
        order: 2; }
      #technologies section.conteneur-technologies.pair .btn {
        background: #000000;
        color: #ffffff;
        border: 2px solid #000000; }
        #technologies section.conteneur-technologies.pair .btn:hover {
          background: #ffffff;
          color: #000000 !important; }

.erreur404 article#post-404 {
  padding: 100px 0; }

.erreur404 article,
.erreur404 section {
  margin: 0 auto; }

.erreur404 h1, .erreur404 h2, .erreur404 h3, .erreur404 h4, .erreur404 h5, .erreur404 h6, .erreur404 p {
  text-align: center; }

.erreur404 h1 {
  font-size: 3.5rem;
  letter-spacing: 0.07rem;
  line-height: 6rem; }
  .erreur404 h1 span {
    font-size: 6rem;
    font-family: 'BebasBold', 'Sans-serif'; }

.erreur404 h2 a {
  color: #000000;
  font-size: 2rem;
  letter-spacing: 0.03rem;
  margin: 20px 0 0 0;
  padding: 50px 0 20px 0;
  position: relative;
  display: block;
  clear: both; }
  .erreur404 h2 a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100px;
    margin: 0 auto;
    height: 1px;
    background: #000000; }

@media only screen and (min-width: 1500px) {
  #service #resultats article > .conteneur_bloc_resultats {
    max-width: 590px;
    float: right;
    padding-right: 40px; } }

@media only screen and (max-width: 1500px) {
  #clinique #notre-mission article {
    padding: 70px 140px; } }

@media only screen and (max-width: 1500px) {
  .details-services {
    background-position: left 10% top 47% !important; } }

@media only screen and (max-width: 1200px) {
  #carrousel-accueil {
    height: 600px; }
    #carrousel-accueil .swiper-slide {
      min-height: 550px !important; }
    #carrousel-accueil .swiper-button-prev,
    #carrousel-accueil .swiper-button-next,
    #carrousel-accueil .swiper-container-rtl .swiper-button-prev {
      top: 37% !important; }
  #clinique #notre-mission article {
    padding: 70px 30px; } }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .nav.footer ul li a,
  .nav.principale ul li a {
    padding: 50px 30px; }
  #technologies section.conteneur-technologies article,
  #technologies section.conteneur-technologies.pair article {
    padding: 70px; } }

@media only screen and (max-width: 1024px) {
  #clinique div.swiper-slide .infos-fiche .telephone a {
    font-size: 12px; } }

@media only screen and (min-width: 992px) {
  .conteneur-menu-mobile {
    display: none; }
  .nav.principale ul .menu-item {
    display: block; }
  #clinique #notre-mission {
    display: flex; }
  #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours tr td {
    padding: 18px 50px 0 25px; }
  #service #resultats article {
    padding: 200px 30px 30px 40px; } }

@media only screen and (max-width: 991px) {
  .header.header aside.header-infos .header-telephone {
    margin-left: 10px !important; }
  .medias-sociaux,
  .jour-semaine {
    display: none; }
  .header-infos .medias-sociaux {
    display: none; }
  .copyright .medias-sociaux {
    display: block; }
  .notification-ouverture {
    position: absolute;
    right: -10px; }
  .conteneur-menu-mobile {
    display: block; }
  .nav.principale ul .menu-item {
    display: none; }
  .details-services .boite {
    left: 0;
    margin: 0 auto; }
  .nav.footer ul li a {
    margin: 0; }
  .nav.footer .logo,
  .nav.principale .logo {
    padding: 10px 20px 20px 0;
    width: 100%; }
    .nav.footer .logo a,
    .nav.principale .logo a {
      overflow: hidden;
      display: block;
      float: right;
      text-align: right; }
      .nav.footer .logo a img,
      .nav.principale .logo a img {
        float: right;
        width: 180px; }
  #technologies section.conteneur-technologies article,
  #technologies section.conteneur-technologies.pair article {
    padding: 70px 30px; }
  #technologies section.conteneur-technologies,
  #technologies section.conteneur-technologies.pair {
    display: block;
    width: 100%; }
  #technologies section.conteneur-technologies:last-of-type article {
    padding-bottom: 90px; }
  #service #utilisation li {
    margin-left: 10%; }
  .swiper-container.s3 .swiper-slide {
    min-height: 400px !important; }
  #contact #conteneur-coordonnees-horaire .formulaire-contact {
    padding-top: 110px;
    padding-bottom: 75px; }
  #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours tr td {
    padding: 18px 20px 0 20px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #carrousel-accueil .swiper-button-next,
  #carrousel-accueil .swiper-button-prev,
  #carrousel-accueil .swiper-container-rtl .swiper-button-prev {
    top: 23% !important; }
  #clinique #notre-mission article {
    padding: 70px 30px; } }

@media only screen and (min-width: 768px) {
  .types-service article figure img {
    width: 125px;
    height: 125px; }
  #clinique div.swiper-slide .infos-fiche {
    padding-left: 30px; }
  footer.footer .footer-global [class*=" icon-"]:before,
  footer.footer .footer-global [class^=icon-]:before {
    text-align: left;
    width: 40px; }
  footer.footer .footer-global .footer-coordonnees address {
    margin-left: 58px; }
    footer.footer .footer-global .footer-coordonnees address span {
      margin-left: -58px; }
      footer.footer .footer-global .footer-coordonnees address span [class*=" icon-"]:before,
      footer.footer .footer-global .footer-coordonnees address span [class^=icon-]:before {
        width: 58px; }
  footer.footer .footer-global .footer-horaire {
    margin-left: 58px; }
    footer.footer .footer-global .footer-horaire span {
      margin-left: -58px; }
    footer.footer .footer-global .footer-horaire td {
      padding-right: 10px; }
  footer.footer .footer-global {
    font-size: 2rem; }
  footer.footer .footer-global p {
    font-size: 2rem; }
  section.bandeau-marketing img.mob {
    display: none !important; }
  section.bandeau-marketing img.desk {
    display: block !important; }
  #contact #conteneur-coordonnees-horaire .coordonnees {
    margin-left: 0; } }

@media only screen and (max-width: 767px) {
  #carrousel-accueil {
    height: 300px; }
    #carrousel-accueil .swiper-slide {
      min-height: 300px !important; }
    #carrousel-accueil .swiper-button-prev,
    #carrousel-accueil .swiper-button-next,
    #carrousel-accueil .swiper-container-rtl .swiper-button-prev {
      top: 37% !important; }
  .swiper-button-next,
  .swiper-button-prev {
    background-size: 30px 60px !important;
    padding: 60px 30px; }
  .swiper-button-next {
    background-position: 20px center !important; }
  .liens-cta {
    margin-top: 0; }
    .liens-cta nav ul {
      border-radius: 0 0 8px 8px; }
    .liens-cta nav li {
      overflow: hidden;
      height: 110px; }
      .liens-cta nav li a {
        border-radius: 0 !important;
        display: block;
        height: 110px;
        min-height: 110px;
        padding-top: 0 !important;
        width: 100%; }
      .liens-cta nav li:first-of-type {
        border-radius: 0 !important; }
      .liens-cta nav li:last-of-type a {
        border-radius: 0 0 8px 8px !important;
        -webkit-box-shadow: inset 0 10px 12px -10px rgba(60, 60, 60, 0.1);
        -moz-box-shadow: inset 0 10px 12px -10px rgba(60, 60, 60, 0.1);
        box-shadow: inset 0 10px 12px -10px rgba(60, 60, 60, 0.1); }
      .liens-cta nav li img,
      .liens-cta nav li h2 {
        float: left;
        padding: 25px 0; }
      .liens-cta nav li figure {
        display: inline-block;
        padding: 0 30px;
        float: left;
        position: static;
        top: 0 !important;
        left: 0; }
        .liens-cta nav li figure img {
          max-width: 60px !important;
          padding: 20px 0; }
      .liens-cta nav li h2 {
        padding: 40px 0 20px 0;
        max-width: 400px;
        float: left;
        position: relative;
        left: 170px;
        text-align: left; }
  #contact #conteneur-coordonnees-horaire .coordonnees {
    margin-left: 0px; }
  .liens-cta nav li:last-of-type {
    -webkit-border-radius: 0 0 8px 8px;
    -moz-border-radius: 0 0 8px 8px;
    border-radius: 0 0 8px 8px;
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3); }
  .liens-cta nav li a h2 {
    font-size: 2rem !important; }
  .texte-presentation,
  .texte-presentation article {
    padding: 30px 15px 35px 15px;
    margin: 0; }
  .types-service {
    padding: 60px 30px 70px 30px; }
    .types-service h1 {
      padding-bottom: 40px; }
  .details-services .boite {
    padding-right: 30px;
    padding-bottom: 105px;
    padding-left: 30px; }
    .details-services .boite h1 {
      padding-left: 0px; }
    .details-services .boite ul {
      padding-left: 65px; }
    .details-services .boite a {
      margin-top: 0;
      margin-left: 70px;
      padding: 20px 30px;
      text-align: center; }
  .nav.footer {
    margin-top: 55px;
    margin-bottom: 55px; }
    .nav.footer ul li {
      width: 100%; }
      .nav.footer ul li a {
        font-size: 1.6rem;
        font-weight: 700;
        margin: 0;
        padding: 10px 0; }
  footer.footer .logo {
    padding: 80px 0 25px 0; }
    footer.footer .logo a img {
      display: block;
      margin: 0 auto; }
  footer.footer .footer-global {
    margin: 0 auto;
    padding-left: 65px;
    width: 80%;
    font-size: 2rem; }
  footer.footer .footer-global p {
    font-size: 2rem; }
  footer.footer .footer-courriel a {
    font-size: 1.4rem; }
  footer.footer .footer-global .footer-coordonnees address {
    margin-bottom: 30px;
    margin-left: 48px; }
    footer.footer .footer-global .footer-coordonnees address span {
      margin-left: -48px; }
  footer.footer .footer-global .footer-horaire {
    margin-left: 48px;
    padding-top: 35px; }
    footer.footer .footer-global .footer-horaire span {
      margin-left: -48px; }
  section.bandeau-marketing img.mob {
    display: block !important; }
  section.bandeau-marketing img.desk {
    display: none !important; }
  #confiance-distinction,
  #notre-equipe,
  #presentation {
    padding-top: 60px;
    padding-bottom: 40px; }
  #confiance-distinction {
    padding-bottom: 50px; }
  #notre-mission {
    padding-top: 10px; }
  #clinique #notre-equipe {
    padding-bottom: 25px; }
  #contact #conteneur-coordonnees-horaire {
    padding: 120px 0; }
    #contact #conteneur-coordonnees-horaire .coordonnees .infos p,
    #contact #conteneur-coordonnees-horaire .coordonnees .adresse {
      padding: 15px 0; }
    #contact #conteneur-coordonnees-horaire .horaire {
      padding-top: 75px;
      padding-left: 80px !important; }
      #contact #conteneur-coordonnees-horaire .horaire::before {
        top: 95px;
        left: 45px; }
  #services .fiche-service .contenu-int-fiche p {
    padding-top: 30px; }
  #services .conteneur-sections-services {
    padding: 0; }
    #services .conteneur-sections-services:last-of-type {
      padding-top: 55px;
      padding-bottom: 75px; }
    #services .conteneur-sections-services.pair {
      padding: 50px 0 60px 0; }
  #services .conteneur-sections-services:first-of-type {
    padding: 70px 0 45px 0; }
  #service #utilisation li {
    margin-left: 15px; }
  #faq #presentation {
    padding-right: 15px;
    padding-left: 15px; }
  #faq .panel-group .panel-collapse {
    padding: 0 40px 0 60px; }
    #faq .panel-group .panel-collapse .question-reponse h3:before {
      left: -35px; }
    #faq .panel-group .panel-collapse .question-reponse p:first-of-type:before {
      left: -35px; }
  #faq .panel-group .panel-heading h2 a {
    padding: 25px 50px 25px 80px !important; }
    #faq .panel-group .panel-heading h2 a .nav-trigger {
      left: 30px; }
  #faq .conteneur-accordeon-faq {
    padding: 70px 15px 105px 15px; }
  #contact #conteneur-coordonnees-horaire {
    padding: 40px 0 20px 0; }
    #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours tr td:first-of-type {
      padding-right: 5px; }
    #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours tr td + td {
      padding-left: 5px; }
    #contact #conteneur-coordonnees-horaire .formulaire-contact {
      padding-top: 150px; }
  #service .opt-retour a {
    border: none;
    display: block; } }

@media only screen and (max-width: 575px) {
  .header-telephone,
  .notification-ouverture {
    font-size: 1.6rem; }
    .header-telephone::before,
    .notification-ouverture::before {
      font-size: 2rem; }
  .swiper-button-next,
  .swiper-button-prev {
    padding: 60px 30px;
    top: 28% !important; }
  .footer-courriel {
    margin-bottom: 20px; }
  .header.header aside.header-infos .header-telephone,
  .header.header aside.header-infos .notification-ouverture {
    max-width: 50%;
    float: right;
    margin-left: 0; }
    .header.header aside.header-infos .header-telephone::before,
    .header.header aside.header-infos .notification-ouverture::before {
      width: 35px; }
    .header.header aside.header-infos .header-telephone span::before,
    .header.header aside.header-infos .notification-ouverture span::before {
      width: 35px; }
  .header.header aside.header-infos .notification-ouverture {
    margin-left: 20px;
    position: absolute;
    right: -10px; }
  .details-services .boite {
    background: rgba(255, 255, 255, 0.8); }
  .btn {
    margin-top: 3rem; }
  .details-services {
    position: relative;
    min-height: 100%; }
    .details-services .boite {
      box-shadow: none;
      border-radius: 0;
      min-height: 700px;
      position: static;
      top: auto;
      margin-top: 0; }
      .details-services .boite a {
        margin-left: 40px; }
  .copyright {
    min-height: 120px; }
    .copyright .hatem {
      font-size: 2rem;
      margin: 45px auto 0 auto; }
      .copyright .hatem strong {
        font-weight: normal; }
      .copyright .hatem a {
        color: #3c3c3c; }
    .copyright .conteneur-medias-sociaux {
      position: absolute;
      text-align: center;
      width: 100%; }
    .copyright .medias-sociaux {
      position: relative;
      display: inline;
      margin: 0;
      padding: 0; }
      .copyright .medias-sociaux a {
        display: inline-block;
        float: none;
        margin: 0; }
        .copyright .medias-sociaux a::before {
          width: 35px; }
  #clinique #notre-mission article {
    padding: 50px 30px; }
  #clinique #notre-mission .mission-img {
    min-height: 400px !important; }
  #clinique .carrousel-conteneur {
    padding-bottom: 100px; }
  .details-services .boite {
    min-height: 100% !important;
    padding-top: 60px;
    padding-bottom: 70px; }
  #service #utilisation {
    padding: 70px 30px 105px 45px; }
    #service #utilisation li {
      font-size: 3rem;
      padding-left: 10px; }
  #service #resultats article {
    padding: 40px 30px 70px 30px !important; }
  #service #resultats #carrousel-service .swiper-button-next,
  #service #resultats #carrousel-service .swiper-button-prev {
    top: 43% !important; }
  #presentation {
    padding-bottom: 50px; }
  #contact [type="email"],
  #contact [type="password"],
  #contact [type="search"],
  #contact [type="tel"],
  #contact [type="text"],
  #contact [type="url"],
  #contact textarea {
    margin-bottom: 5px;
    padding: 13px 15px; }
  #contact #conteneur-coordonnees-horaire .coordonnees {
    margin-left: 0; }
  .footer-global {
    font-size: 1.6rem; }
  .footer-global p {
    font-size: 1.6rem; } }

@media only screen and (min-width: 480px) and (max-width: 530px) {
  footer.footer .footer-global {
    width: 90%; } }

@media only screen and (max-width: 480px) {
  .header.header aside.header-infos {
    padding-right: 5px; }
    .header.header aside.header-infos .notification-ouverture {
      max-width: 60%;
      right: 15px; }
  .liens-cta nav li a h2 {
    left: 120px; }
  .types-service article div {
    padding-left: 0; }
  .conteneur-liens-cta {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; }
  footer.footer .footer-global {
    padding-left: 0;
    width: 100%; }
    footer.footer .footer-global .footer-horaire td {
      padding-right: 10px !important; }
  footer.footer .footer-global [class^="icon-"]:before,
  footer.footer .footer-global [class*=" icon-"]:before {
    width: 35px; }
  footer.footer .footer-global .footer-coordonnees address {
    margin-left: 52px; }
    footer.footer .footer-global .footer-coordonnees address span {
      margin-left: -52px; }
  footer.footer .footer-global .footer-horaire {
    margin-left: 52px; }
    footer.footer .footer-global .footer-horaire span {
      margin-left: -52px; }
  .types-service article figure {
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0; }
  #clinique #carrousel-clinique {
    padding-left: 30px;
    padding-right: 30px; }
    #clinique #carrousel-clinique .swiper-button-next {
      right: 30px !important; }
    #clinique #carrousel-clinique .swiper-button-prev {
      left: 30px !important; }
  #clinique #notre-equipe div.swiper-slide .infos-fiche {
    padding-left: 70px; }
    #clinique #notre-equipe div.swiper-slide .infos-fiche .telephone {
      margin-left: -62px; }
      #clinique #notre-equipe div.swiper-slide .infos-fiche .telephone::before {
        content: '\e800'; }
  #contact #conteneur-coordonnees-horaire .formulaire-contact p input[type=submit] {
    width: 100%; }
  #contact #conteneur-coordonnees-horaire .horaire::before {
    left: 35px; }
  #contact #conteneur-coordonnees-horaire table.mabel-bhi-businesshours {
    width: 100%; }
  #contact #conteneur-coordonnees-horaire .coordonnees .infos p + p a {
    font-size: 1.7rem; }
  #service #utilisation li {
    width: 100% !important;
    padding-left: 25px; }
    #service #utilisation li::before {
      left: -15px; }
  #service #utilisation {
    padding: 70px 15px 105px 15px; } }

@media only screen and (max-width: 440px) {
  h1 {
    font-size: 4rem; }
  .btn {
    margin-left: 0 !important;
    padding-left: 0;
    padding-right: 0;
    width: 100%; }
  .texte-presentation .btn {
    margin-left: 40px; }
  .copyright {
    padding-right: 0;
    padding-left: 0; }
  #services .fiche-service .contenu-int-fiche h3 span {
    font-size: 2.4rem;
    line-height: 2.5rem;
    padding: 0 20px; }
  #services .fiche-service .contenu-int-fiche p {
    font-size: 19px; }
  #technologies h2 {
    font-size: 3.6rem; } }

@media only screen and (max-width: 380px) {
  .btn {
    font-size: 18px; }
  nav#nav-mobile a {
    padding: 19px; }
  h1 {
    font-size: 3rem; }
  .liens-cta nav li a h2 {
    font-size: 1.8rem !important;
    left: 110px;
    line-height: 2rem;
    padding-right: 15px; }
  .liens-cta nav li figure {
    padding: 0 30px; }
    .liens-cta nav li figure img {
      max-width: 50px; }
  .header.header aside.header-infos {
    padding-top: 20px;
    height: 70px; }
  .notification-ouverture {
    position: static !important;
    padding-left: 0 !important;
    margin-left: 10px !important;
    max-width: 100% !important; }
  .texte-presentation .btn {
    margin-left: 20px;
    padding: 20px 0; }
  .details-service .boite a {
    margin-left: 25px; }
  .types-service figure {
    padding-left: 0;
    padding-right: 10px; }
  .types-service h2 {
    font-size: 3.8rem; }
  .fiche-service p {
    font-size: 1.7rem; }
  #service #utilisation h2 {
    font-size: 3.6rem !important; }
  #service #utilisation li {
    font-size: 2.4rem !important; }
    #service #utilisation li::before {
      top: 10px;
      left: -15px;
      width: 20px;
      height: 15px; }
  #faq .panel-group .panel-heading h2 a {
    font-size: 2.2rem;
    padding: 25px 20px 25px 50px !important; }
    #faq .panel-group .panel-heading h2 a .nav-trigger {
      left: 15px; }
  #faq .panel-group .panel-collapse {
    padding: 0 20px 0 40px; }
    #faq .panel-group .panel-collapse .question-reponse h3 {
      font-size: 2.4rem; }
      #faq .panel-group .panel-collapse .question-reponse h3:before {
        left: -25px; }
    #faq .panel-group .panel-collapse .question-reponse p {
      font-size: 1.8rem; }
      #faq .panel-group .panel-collapse .question-reponse p:first-of-type:before {
        left: -25px; }
  #contact .coordonnees .infos p span:before {
    width: 40px; }
  #contact #conteneur-coordonnees-horaire .formulaire-contact p input[type=submit] {
    padding-left: 0;
    padding-right: 0; }
  #contact #conteneur-coordonnees-horaire .horaire {
    padding-left: 70px !important; }
    #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours {
      padding-right: 10px; }
      #contact #conteneur-coordonnees-horaire .horaire .mabel-bhi-businesshours td {
        font-size: 1.6rem !important; }
  #contact #conteneur-coordonnees-horaire .notice-ouverture {
    margin-left: 20px; }
  .copyright .hatem {
    font-size: 1.6rem; } }

@media only screen and (max-width: 320px) {
  .header.header aside.header-infos {
    padding-top: 5px; }
  .header.header aside.header-infos .header-telephone,
  .header.header aside.header-infos .notification-ouverture {
    max-width: 100% !important; }
  #clinique div.swiper-slide .infos-fiche {
    padding-left: 20px; }
  #contact #conteneur-coordonnees-horaire a {
    font-size: 1.4rem !important; }
  .footer-global {
    font-size: 1.4rem !important; }
  .footer-global p {
    font-size: 1.4rem !important; } }

/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links for images, or javascript/internal links
     */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }
